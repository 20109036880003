import React from "react";
import { graphql, navigate } from "gatsby";

import Layout from "../components/Layout";
import SectionLayout from "../components/SectionLayout";
import SEO from "../components/seo";
import BreadCrumb from "../components/BreadCrumb";

const Categories = [
  { id: "common_questions", title: "FAQs" },
  { id: "account_privacy", title: "Account & Privacy" },
  { id: "experiment", title: "Experiment Design" },
  { id: "participations", title: "Participation and Crowdsourcing" },
  { id: "visualisations", title: "Visualizations" }
];
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  location
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  const siteTitle = data.site.siteMetadata.title;
  // find if there is a match
  const rootname = "Help";
  const currentname = frontmatter.title;

  // take from url question's category to pass it in BreadCrumb
  const category = location.href ? location.href.split("/")[4] : null;

  return (
    <Layout location={location} title={siteTitle} style={{ border: "none" }}>
      <SEO
        title="Loceye | Understanding Humans"
        keywords={[`loceye`, `eyetracking`, `marketing`, `ux`, "help"]}
      />

      <div className="container help-post">
        <SectionLayout title={frontmatter.title}>
          <div className="row">
              <BreadCrumb
                rootname={rootname}
                category={category}
                currentname={currentname}
              />
          </div>
          <div className="row">
            <div className="col-md-12">
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </SectionLayout>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;
